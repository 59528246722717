import { dehydrate, QueryClient } from '@tanstack/react-query';
import { baseUrl } from 'config';
import Head from 'next/head';
import { useRouter } from 'next/router';

import useAuthentication from '@/components/Authentication/useAuthentication';
import useOnboardingRedirection from '@/components/Authentication/useOnboardingRedirection';
import LandingMozza from '@/components/LandingMozza/LandingMozza/LandingMozza';
import ModalLoader from '@/Molecules/Loader/ModalLoader/ModalLoader';
import { apiGetGoogleReviewsCount } from '@/shared/API/apiGetGoogleReviews';
import { apiGetSubjects } from '@/shared/API/apiGetSubjects';
import structuredHomeData from '@/utils/structuredHomeData';

const MozzaStudent = () => {
  const { authentication } = useAuthentication();
  const { query } = useRouter();

  useOnboardingRedirection({ authentication });

  return (
    <>
      {query.method && <ModalLoader />}
      <Head>
        <title>
          Cours particuliers & Soutien scolaire sur mesure - Les Sherpas
        </title>
        <meta
          name="description"
          content="Trouvez le Prof particulier idéal ✓ Professeurs certifiés ✓ Excellence académique (HEC, Centrale, Sciences Po..) ✓ Collège, Lycée, Prépa, Fac ✓ Dès 15€/h"
        />
        <meta property="og:site_name" content="Les Sherpas" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://sherpas.com/" />
        <meta
          property="og:title"
          content="Cours particuliers & Soutien scolaire sur mesure - Les Sherpas"
        />
        <meta
          property="og:description"
          content="Trouvez le Prof particulier idéal ✓ Professeurs certifiés ✓ Excellence académique (HEC, Centrale, Sciences Po..) ✓ Collège, Lycée, Prépa, Fac ✓ Dès 15€/h"
        />
        <meta
          property="og:image"
          content="https://sherpas.com/p/files/cours-particuliers.webp"
        />
        <link rel="canonical" href={baseUrl} />
        <script
          key="structured-data"
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredHomeData),
          }}
        />
      </Head>
      <LandingMozza />
    </>
  );
};

export async function getStaticProps() {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery(['subject'], apiGetSubjects);
  await queryClient.prefetchQuery(
    ['GooglePlaceReviewsCount'],
    apiGetGoogleReviewsCount
  );

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      isTvBannerDisplayed: true,
    },
  };
}

export default MozzaStudent;
